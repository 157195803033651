import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { redirect } from '../../common/utils/redirectionUtils'

import './CreateAccount.scss'
import CustomMap from '../../components/CustomMap/CustomMap'
import LogoLarge from '../../assets/LogoLarge'
import LoginLeft from '../Login/LoginLeft'
import LocationIcon from '../../assets/LocationIcon'
import { PAGE_CONST } from '../../common/constant/page-constant'
import { createAccountHelper } from '../../helper/create-account-helper'

function CreateAccount(props) {
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [showMap, setShowMap] = useState(false)

    const [pharmacyName, setPharmacyName] = useState(undefined)
    const [category, setCategory] = useState(undefined)
    const [locationString, setLocationString] = useState(undefined)
    const [latitude, setLatitude] = useState(undefined)
    const [longitude, setLongitude] = useState(undefined)
    const [addressComponent, setAddressComponent] = useState(undefined)
    const [placeId, setPlaceId] = useState(undefined)
    const [ownerName, setOwnerName] = useState(undefined)
    const [areaCode, setAreaCode] = useState(undefined)
    const [ownerPhoneNumber, setOwnerPhoneNumber] = useState(undefined)
    const [ownerEmail, setOwnerEmail] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [confirmPassword, setConfirmPassword] = useState(undefined)
    const [err, setErr] = useState('')
    const [isPasswordErrorVisible, setIsPasswordErrorVisible] = useState(false)

    const navigate = useNavigate()
    const handleSignIn = () => {
        redirect(navigate, PAGE_CONST.LOGIN)
    }

    const handleLocationInputClick = () => {
        setShowMap(true)
        // setLocationString("test String");
        // setLatitude(13.999);
        // setLongitude(83.999);
    }

    const handleLocationSave = (
        selected,
        selectedString,
        placeId,
        addressComponent
    ) => {
        console.log(
            'selected: ',
            selected,
            ' selectedString: ',
            selectedString,
            'placeId',
            placeId,
            'addressCom: ',
            addressComponent
        )
        setLocationString(selectedString)
        setLatitude(selected?.lat)
        setLongitude(selected?.lng)
        setPlaceId(placeId)
        setAddressComponent(addressComponent)
        setShowMap(false)
    }

    const handleCreateAccountValidation = () => {
        setIsPasswordErrorVisible(false)
        if (password !== confirmPassword) {
            setIsPasswordErrorVisible(true)
            return false
        }

        return (
            pharmacyName &&
            category &&
            locationString &&
            latitude &&
            longitude &&
            ownerName &&
            ownerPhoneNumber &&
            password &&
            confirmPassword
        )
    }

    const handleCreateAccount = () => {
        if (!handleCreateAccountValidation()) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        let data = {
            pharmacyName,
            category,
            locationString,
            latitude,
            longitude,
            placeId,
            addressComponent,
            ownerName,
            areaCode,
            ownerPhoneNumber,
            ownerEmail,
            password
        }

        console.log('res: ', data)

        createAccountHelper(data)
            .then(res => {
                console.log('res: ', res)

                redirect(navigate, PAGE_CONST.LOGIN)
            })
            .catch(err => {
                setErr(err)
            })
    }

    const handleLocationCancel = () => {
        setLatitude(undefined)
        setLongitude(undefined)
        setLocationString(undefined)
        setPlaceId(undefined)
        setAddressComponent(undefined)
        setShowMap(false)
    }

    return (
        <>
            <main className="loginPage createAccount dFlex jcSpaceBetween flexNowrap">
                <LoginLeft />
                <div className="loginRight">
                    <div className="loginRightControl">
                        <div className="login-content">
                            <div className="welcomeHeading">
                                <span>CREATE ACCOUNT</span>
                            </div>
                            <div className="welcomeDesc">
                                Lorem ipsum dolor sit amet consectetur. Arcu
                                mattis donec eu etiam amet.
                            </div>
                        </div>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="formSection">
                                <div className="formHeading">
                                    Pharmacy Details
                                </div>
                                <div className="formInline dFlex flexNowrap">
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="pharmacyname"
                                            placeholder="Pharmacy Name"
                                            value={pharmacyName}
                                            onChange={e => {
                                                setErr('')
                                                setPharmacyName(e.target.value)
                                            }}
                                            required
                                        />
                                        <label for="pharmacyname">
                                            Pharmacy Name
                                        </label>
                                        {isFieldErrorVisible &&
                                            !pharmacyName && (
                                                <div class="invalidFeedback">
                                                    Please select a valid
                                                    Pharmacy Name.
                                                </div>
                                            )}
                                    </div>
                                    <div className="formGroup formFloating ml-4 fgrow1">
                                        <select
                                            className="formSelect"
                                            id="floatingSelect"
                                            onChange={e => {
                                                setErr('')
                                                console.log(
                                                    'value: ',
                                                    e.target.value
                                                )
                                                setCategory(e.target.value)
                                            }}>
                                            <option
                                                value="none"
                                                selected
                                                disabled
                                                hidden>
                                                Select an Option
                                            </option>
                                            <option value="RETAIL">
                                                Retail
                                            </option>
                                            <option value="HOSPITAL">
                                                Hospital
                                            </option>
                                        </select>
                                        <label for="floatingSelect">
                                            Category
                                        </label>
                                        {isFieldErrorVisible && !category && (
                                            <div class="invalidFeedback">
                                                Please choose Category.
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="formGroup formFloating fgrow1 relative">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="location"
                                        placeholder="Location"
                                        value={locationString}
                                        autocomplete="off"
                                        onClick={handleLocationInputClick}
                                        required
                                    />
                                    <label for="location">Location</label>
                                    {isFieldErrorVisible && !locationString && (
                                        <div class="invalidFeedback">
                                            Please Choose Location.
                                        </div>
                                    )}
                                    <div
                                        className="positionedIcon"
                                        onClick={() =>
                                            handleLocationInputClick()
                                        }>
                                        <LocationIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="formSection">
                                <div className="formHeading">Owner Details</div>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="name"
                                        placeholder="Name"
                                        value={ownerName}
                                        onChange={e => {
                                            setErr('')
                                            setOwnerName(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="name">Name</label>
                                    {isFieldErrorVisible && !ownerName && (
                                        <div class="invalidFeedback">
                                            Please Enter Name.
                                        </div>
                                    )}
                                </div>
                                <div className="formInline dFlex flexNowrap">
                                    <div className="formGroup formFloating fgrow1 maxWidth mb-0">
                                        <select
                                            className="formSelect"
                                            id="floatingSelect"
                                            onChange={e => {
                                                setErr('')
                                                setAreaCode(e.target.value)
                                            }}>
                                            {/* <option value="1">+91</option> */}
                                            <option value="2">+88</option>
                                        </select>
                                        <label for="floatingSelect">
                                            Country
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please choose country code.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating ml-4 fgrow1 mb-0">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="phone"
                                            pattern="[0-9]{10}"
                                            maxLength={11}
                                            minLength={10}
                                            placeholder="Phone Number"
                                            value={ownerPhoneNumber}
                                            onChange={e => {
                                                setErr('')
                                                const inputText = e.target.value
                                                const numericInput =
                                                    inputText.replace(/\D/g, '')
                                                setOwnerPhoneNumber(
                                                    numericInput
                                                )
                                            }}
                                            required
                                        />
                                        <label for="phone">Phone Number</label>
                                        {isFieldErrorVisible &&
                                            !ownerPhoneNumber && (
                                                <div class="invalidFeedback">
                                                    Please select a valid Phone
                                                    Number.
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="formGroup formFloating fgrow1">
                                    <input
                                        type="text"
                                        className="formControl"
                                        id="email"
                                        placeholder="Email"
                                        value={ownerEmail}
                                        onChange={e => {
                                            setErr('')
                                            setOwnerEmail(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="name">Email</label>
                                    {isFieldErrorVisible && !ownerEmail && (
                                        <div class="invalidFeedback">
                                            Please Enter Email.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="formSection">
                                <div className="formHeading">Password</div>
                                <div className="formGroup formFloating">
                                    <input
                                        type="password"
                                        className="formControl"
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={e => {
                                            setErr('')
                                            setPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="password">Password</label>
                                    {isFieldErrorVisible && !password && (
                                        <div class="invalidFeedback">
                                            Please enter a valid Password.
                                        </div>
                                    )}
                                </div>
                                <div className="formGroup formFloating">
                                    <input
                                        type="password"
                                        className="formControl"
                                        id="confirmpassword"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={e => {
                                            setErr('')
                                            setConfirmPassword(e.target.value)
                                        }}
                                        required
                                    />
                                    <label for="confirmpassword">
                                        Confirm Password
                                    </label>
                                    {isFieldErrorVisible &&
                                        !confirmPassword && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Confirm
                                                Password.
                                            </div>
                                        )}

                                    {isPasswordErrorVisible &&
                                        password !== confirmPassword && (
                                            <div class="invalidFeedback">
                                                Password does not match.
                                            </div>
                                        )}
                                </div>
                            </div>
                            {err && <div class="invalidFeedback">{err}</div>}
                            <button
                                className="btn btnPrimary block space"
                                onClick={handleCreateAccount}>
                                Create Account
                            </button>
                        </form>
                        <div className="createAccountWrap">
                            <div className="createAccountText">
                                Already a member?
                            </div>
                            <div
                                className="createAccount"
                                onClick={handleSignIn}>
                                SIGN IN
                            </div>
                        </div>
                        <div className="loginFooter"></div>
                    </div>
                    {showMap && (
                        <div className="locationContainer">
                            <div className="welcomeHeading">
                                <span>LOCATION</span>
                            </div>

                            {/* <LocatorButton mapObject={mapObject} /> */}
                            {/* <CustomMap setMapObject={setMapObject} /> */}
                            <CustomMap
                                onClose={handleLocationCancel}
                                onSave={handleLocationSave}
                            />
                        </div>
                    )}
                </div>
            </main>
        </>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
